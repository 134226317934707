/**
 * 
 * 
 * :: AJAX request for Locations by Surgery Type
 * 
 * 
 */


// Function to get the selected checkbox IDs and run the AJAX query

// function getSelectedCheckboxIds() {
//     let selectedCheckboxIds = [];
//     document.querySelectorAll('.global-search-checkbox__surgery').forEach(function(checkbox) {

//         console.log('Checkbox value:', checkbox.value); // Debugging line
//         if (checkbox.checked) {
//             selectedCheckboxIds.push(checkbox.value);
//         }

//     });
//     console.log('Selected Checkbox IDs:', selectedCheckboxIds); // Debugging line
//     return selectedCheckboxIds;
// }

function getSelectedCheckboxIds() {
    let selectedCheckboxIds = [];
    document.querySelectorAll('.global-search-checkbox__surgery__input').forEach(function(checkbox) {
        // console.log('Checkbox:', checkbox); // Debugging line
        // console.log('Checkbox value:', checkbox.value); // Debugging line
        // console.log('Checkbox checked:', checkbox.checked); // Debugging line

        if (checkbox.checked) {
            selectedCheckboxIds.push(checkbox.value);
        }
    });
    // console.log('Selected Checkbox IDs:', selectedCheckboxIds); // Debugging line
    return selectedCheckboxIds;
}


// Function to run AJAX query with selected checkboxes
function get_posts_locations(params) {
    let varContent = document.querySelector('.t-p-code__global_locations_modal__input_response');
    let varMapEl = document.querySelector('.t-p-code__global_locations_modal__map');

    jQuery.ajax({
        type: 'post',
        dataType: 'json',
        url: scripts_nonce.ajax_url,
        data: {
            action: "locations_filter",
            nonce: scripts_nonce.nonce,
            params: params,
        },
        success: function (data, textStatus, XMLHttpRequest) {
            if (data.status === 200) {
                // console.log('Search results:', data.content);
                varContent.innerHTML = data.content;

                if (data.content && data.content.trim().length > 0) {
                    varMapEl.classList.add('has-content');
                } else {
                    varMapEl.classList.remove('has-content');
                }
            } else {
                varMapEl.classList.remove('has-content');
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            // console.error('AJAX request failed with status:', textStatus, errorThrown);
        },
        complete: function (data, textStatus) {
            // console.log('AJAX request completed:', textStatus);
        }
    });
}

// Function to add event listeners to checkboxes
function addEventListeners() {
    document.querySelectorAll('.global-search-checkbox__surgery__input').forEach(function(checkbox) {
        checkbox.addEventListener('change', function () {
            // console.log('Checkbox changed:', checkbox.checked); // Debugging line
            let selectedCheckboxIds = getSelectedCheckboxIds();
            // console.log('Selected Checkbox IDs:', selectedCheckboxIds); // Debugging line
            let params = {
                posts_per_page: -1,
                post_type: 'distributors', // Assuming the post type is 'distributors'
                surgery_ids: selectedCheckboxIds // Add the selected checkbox IDs to the params
            };
            get_posts_locations(params);
        });
    });
}

// Initial event listener setup for elements already in the DOM
document.addEventListener('DOMContentLoaded', function() {
    addEventListeners();
});

// Observer function for dynamically added nodes
function checkAddedNodesCheckboxSurgeries(addedNodes) {
    addedNodes.forEach(function(node) {
        if (node.nodeType === 1) {
            if (node.matches('.global-search-checkbox__surgery__input')) {
                // console.log('Checkbox added to the DOM:', node); // Debugging line
                node.addEventListener('change', function () {
                    let selectedCheckboxIds = getSelectedCheckboxIds();
                    // console.log('Checkbox changed. Selected Checkbox IDs:', selectedCheckboxIds); // Debugging line
                    let params = {
                        posts_per_page: -1,
                        post_type: 'distributors',
                        surgery_ids: selectedCheckboxIds
                    };
                    get_posts_locations(params);
                });
            }
            if (node.childNodes.length > 0) {
                checkAddedNodesCheckboxSurgeries(node.childNodes);
            }
        }
    });
}

// Create a new MutationObserver instance
var mutationObserver = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        if (mutation.addedNodes.length > 0) {
            checkAddedNodesCheckboxSurgeries(mutation.addedNodes);
        }
    });
});

// Start observing the document for added nodes
mutationObserver.observe(document.documentElement, {
    childList: true,
    subtree: true
});

// Initial event listener setup for elements already in the DOM
document.addEventListener('DOMContentLoaded', function() {
    addEventListeners();
});