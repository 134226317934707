/**
 * 
 * 
 * :: AJAX request for Locations
 * 
 * 
 */
let inputLocationsSearch = document.querySelector('#global-location-search-bar');

// :: observer looking for new links when added to DOM

// console.log('Location Search : ', inputLocationsSearch );



        // alert();

        /**
         * ----
         * 
         * Ajax
         * 
         * ----
         */

        function get_posts_locations(params) {

            let varContent = document.querySelector('.t-p-code__global_locations_modal__input_response');

            let varMapEl = document.querySelector('.t-p-code__global_locations_modal__map');
        
            jQuery.ajax({
                type: 'post',
                dataType: 'json',
                url: scripts_nonce.ajax_url,
                data: {
                    action: "locations_filter",
                    nonce: scripts_nonce.nonce,
                    params: params,
                },
                success: function (data, textStatus, XMLHttpRequest) {
                    if (data.status === 200) {

                        // console.log('Search results:', data.content);

                        // Handle the search results here, for example:
                        varContent.innerHTML = data.content;
                        


                        if (data.content && data.content.trim().length > 0) {
                            varMapEl.classList.add('has-content');
                        } else {
                            varMapEl.classList.remove('has-content');
                        }
                    } else {
                        // If status is not 200, consider it as no content
                        varMapEl.classList.remove('has-content');
                    }
                    
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    // console.error('AJAX request failed with status:', textStatus, errorThrown);
                },
                complete: function (data, textStatus) {
                    // console.log('AJAX request completed:', textStatus);
                }
            });
        }

        // Run query

        // console.log('filter Params', $params);

        // get_posts_locations($params);

        // :: Disable standerd click behaviour    
        // e.preventDefault();





// :: Observer function when modal added to dom
// :: Resource : https://medium.com/sessionstack-blog/how-javascript-works-tracking-changes-in-the-dom-using-mutationobserver-86adc7446401
function checkAddedNodes(addedNodes) {

    addedNodes.forEach(function(node) {

        // If the node is an element
        if (node.nodeType === 1) {

            // Check if the added node is the element you're looking for
            if (node.id === 'global-location-search-bar') {

                // console.log('Element with ID global-location-search-bar has been added to the DOM.');
                
                node.addEventListener('input', function () {
                    let query = node.value;
                    let params = {
                        posts_per_page: -1,
                        post_type: 'distributors', // Assuming the post type is 'distributors'
                        search_query: query // Add the input value to the params
                    };
                    get_posts_locations(params);
                });
            }
            
            
            // Recursively check the child nodes of the added node
            if (node.childNodes.length > 0) {

                checkAddedNodes(node.childNodes);

            }
        }
    });
}

// Create a new MutationObserver instance
var mutationObserver = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        // Check for added nodes
        if (mutation.addedNodes.length > 0) {
            checkAddedNodes(mutation.addedNodes);
        }
    });
});

// Start observing the document for added nodes
mutationObserver.observe(document.documentElement, {
    childList: true,
    subtree: true
});


