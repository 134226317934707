/**
 * 
 * 
 * :: AJAX request for Locations by Product Type
 * 
 * 
 */


// Function to get the selected checkbox IDs and run the AJAX query
// function handleCountryClick(postId) {
//     console.log('Post ID:', postId); // Debugging line
//     let params = {
//         post_id: postId
//     };
//     get_posts_locations(params);
// }

// // Observer function for dynamically added nodes (country groups)
// function checkAddedNodesCountryGroups(addedNodes) {
//     addedNodes.forEach(function(node) {
//         if (node.nodeType === 1) {
//             if (node.matches('g[data-post-id]')) {
//                 let postId = node.getAttribute('data-post-id');
//                 console.log('Node added with post ID:', postId); // Debugging line
//                 node.addEventListener('click', function () {
//                     handleCountryClick(postId);
//                 });
//             }
//             if (node.childNodes.length > 0) {
//                 checkAddedNodesCountryGroups(node.childNodes);
//             }
//         }
//     });
// }

// // Function to run AJAX query with selected parameters
// function get_posts_locations(params) {
//     console.log('AJAX params:', params); // Debugging line
//     let varContent = document.querySelector('.t-p-code__global_locations_modal__input_response');
//     let varMapEl = document.querySelector('.t-p-code__global_locations_modal__map');

//     jQuery.ajax({
//         type: 'post',
//         dataType: 'json',
//         url: scripts_nonce.ajax_url,
//         data: {
//             action: "locations_filter",
//             nonce: scripts_nonce.nonce,
//             params: params,
//         },
//         success: function (data, textStatus, XMLHttpRequest) {
//             if (data.status === 200) {
//                 console.log('Search results:', data.content);
//                 varContent.innerHTML = data.content;

//                 if (data.content && data.content.trim().length > 0) {
//                     varMapEl.classList.add('has-content');
//                 } else {
//                     varMapEl.classList.remove('has-content');
//                 }

//             } else {
//                 varMapEl.classList.remove('has-content');
//             }
//         },
//         error: function (XMLHttpRequest, textStatus, errorThrown) {
//             console.error('AJAX request failed with status:', textStatus, errorThrown);
//         },
//         complete: function (data, textStatus) {
//             console.log('AJAX request completed:', textStatus);
//         }
//     });
// }

// // Create a new MutationObserver instance for country groups
// var mutationObserverCountryGroups = new MutationObserver(function(mutations) {
//     mutations.forEach(function(mutation) {
//         if (mutation.addedNodes.length > 0) {
//             checkAddedNodesCountryGroups(mutation.addedNodes);
//         }
//     });
// });

// // Start observing the document for added country groups
// mutationObserverCountryGroups.observe(document.documentElement, {
//     childList: true,
//     subtree: true
// });

// // Initial event listener setup for elements already in the DOM
// document.addEventListener('DOMContentLoaded', function() {
//     document.querySelectorAll('g[data-post-id]').forEach(function(node) {
//         let postId = node.getAttribute('data-post-id');
//         console.log('Initial node with post ID:', postId); // Debugging line
//         node.addEventListener('click', function () {
//             handleCountryClick(postId);
//         });
//     });
// });

// Function to get the selected checkbox IDs and run the AJAX query
function handleCountryClick(postId) {
    // console.log('Post ID:', postId); // Debugging line

    // Example: if the country should have multiple post IDs
    let postIds = [postId];
    if (postId === '954') { // Assuming 123 is the ID for the specific country
        postIds = [954, 961]; // IDs 123 and 456 for that country
    }

    let params = {
        post_id: postIds
    };
    get_posts_locations(params);
}

// Observer function for dynamically added nodes (country groups)
function checkAddedNodesCountryGroups(addedNodes) {
    addedNodes.forEach(function(node) {
        if (node.nodeType === 1) {
            if (node.matches('g[data-post-id]')) {
                let postId = node.getAttribute('data-post-id');
                // console.log('Node added with post ID:', postId); // Debugging line
                node.addEventListener('click', function () {
                    handleCountryClick(postId); // Pass post ID to handleCountryClick
                });
            }
            if (node.childNodes.length > 0) {
                checkAddedNodesCountryGroups(node.childNodes);
            }
        }
    });
}

// Function to run AJAX query with selected parameters
function get_posts_locations(params) {
    // console.log('AJAX params:', params); // Debugging line
    let varContent = document.querySelector('.t-p-code__global_locations_modal__input_response');
    let varMapEl = document.querySelector('.t-p-code__global_locations_modal__map');

    // console.log('Map Found again');

    jQuery.ajax({
        type: 'post',
        dataType: 'json',
        url: scripts_nonce.ajax_url,
        data: {
            action: "locations_filter",
            nonce: scripts_nonce.nonce,
            params: params,
        },
        success: function (data, textStatus, XMLHttpRequest) {
            if (data.status === 200) {
                // console.log('Search results:', data.content);
                // console.log('Search results:', data.post_ids);
                // console.log('Search results:', data.found); 
                // console.log('Post array:', data.post_array);
                varContent.innerHTML = data.content;

                if (data.content && data.content.trim().length > 0) {
                    varMapEl.classList.add('has-content');
                } else {
                    varMapEl.classList.remove('has-content');
                }

            } else {
                varMapEl.classList.remove('has-content');
            }
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            // console.error('AJAX request failed with status:', textStatus, errorThrown);
        },
        complete: function (data, textStatus) {
            // console.log('AJAX request completed:', textStatus);
        }
    });
}

// Create a new MutationObserver instance for country groups
var mutationObserverCountryGroups = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
        if (mutation.addedNodes.length > 0) {
            checkAddedNodesCountryGroups(mutation.addedNodes);
        }
    });
});

// Start observing the document for added country groups
mutationObserverCountryGroups.observe(document.documentElement, {
    childList: true,
    subtree: true
});

// Initial event listener setup for elements already in the DOM
document.addEventListener('DOMContentLoaded', function() {
    document.querySelectorAll('g[data-post-id]').forEach(function(node) {
        let postId = node.getAttribute('data-post-id');
        console.log('Initial node with post ID:', postId); // Debugging line
        node.addEventListener('click', function () {
            handleCountryClick(postId); // Pass post ID to handleCountryClick
        });
    });
});